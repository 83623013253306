exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alle-spiele-index-js": () => import("./../../../src/pages/alle-spiele/index.js" /* webpackChunkName: "component---src-pages-alle-spiele-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-fragen-6-js": () => import("./../../../src/templates/fragen-6.js" /* webpackChunkName: "component---src-templates-fragen-6-js" */),
  "component---src-templates-game-page-js": () => import("./../../../src/templates/game-page.js" /* webpackChunkName: "component---src-templates-game-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-playstation-page-js": () => import("./../../../src/templates/playstation-page.js" /* webpackChunkName: "component---src-templates-playstation-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-regeln-blog-post-js": () => import("./../../../src/templates/regeln-blog-post.js" /* webpackChunkName: "component---src-templates-regeln-blog-post-js" */),
  "component---src-templates-regeln-page-js": () => import("./../../../src/templates/regeln-page.js" /* webpackChunkName: "component---src-templates-regeln-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

